body {
  .homepage-search{
    .search-box{
      .search-options{
        .action-type{
          .list-inline-item{
            label{
              background-color: #22201D !important;
            }
          }
        }
      }
    }
  }
  .header-container {
    .btn-menu {
      .menu-text {
        color: color('primary');
      }

      .menu-icon > span {
        background-color: color('primary');
      }
    }
    .header-nav-top {
      background-color: #1C1A18 !important;
      text-align: left;
    }
    .header-nav-main {
      background: #22201D !important;
      .header-nav{
        font-size: 15px !important;
      }
      .img-fluid {
        height: 72px;
        margin: 18px;
      }
      li.nav-item.list-inline-item:hover {
        border-bottom: none;
      }
      li.nav-item.list-inline-item.active {
        border-bottom: none;
        a{
          color: #F78F1E !important;
        }
      }
    }
    .nav-link{
      color: #CCCCCC;
      padding: 0 20px !important;
    }
    .nav-link:hover {
      color: #F78F1E;
    }
    .nav-contact-text{
      color: #CCCCCC;
    }
  }
  .main-footer {
    .footer-section.footer-main {
      .footer-nav-links{
        .img-fluid{
          margin-bottom: 20px;
        }
      }
      .footer-nav ul li a {
        color: #D2D3D4;
      }
      .footer-contact {
        li {
          color: #D2D3D4 !important;

          a {
            color: #D2D3D4 !important;
          }
        }
      }
    }
    .footer-section.footer-bottom {
      color: #9EA0A3 !important;
    }
  }
  .social-icons {
    margin-left: -3px;
    .social-icon {
      border: none !important;
      width: 28px !important;
      height: 28px !important;
    }
  }
  @media (max-width: 991px) {
    .social-media {
      display: flex;
      justify-content: center;
    }
  }
  .social-media {
    .a2a_svg{
      border: 1px solid dimgray !important;
      height: 30px !important;
      width: 30px !important;
    }
    svg{
      background: #22201D !important;
      path{
        fill: #FFFFFF !important;
      }
    }
  }
  .footer-section h4.block-title::after {
    background: #F78F1E !important;
    height: 2px !important;
  }
  .company-name{
    display: none;
  }
  .input-group-append{
    button{
      background-color: #F78F1E !important;
      width: 50px;
    }
  }
  .btn-search-trigger{
    background: #F78F1E;

  }
  .search-box-triggers{
    padding-right: 4px;
    .list-inline-item{

      float: right;
      a {
        color: #FFFFFF;
        i{
          padding-left: 15px;
        }
      }
    }
  }
  .modal-body{
    .input-group-append{
      .btn{
        display: none;
      }
    }
  }
  .section-separator-home{
     background: #F78F1E !important;
     height: 2px !important;
  }
  .range-col .range-wrapper.active{
    .list-inline-item{
      background: #FFFFFF;
      float: left !important;
    }
  }
  .featured-text-section .section-content .section-separator{
    display: none;
  }
  .featured-text-section .section-content{
    background: none !important;
    max-width: 500px;
    .title-image{
      margin-top: 20px;
      float: right;
    }
    .section-title{
      margin-top: 20px;
      font-size: 50px !important;
      font-weight: 600;
      float: right;
      display: flex;
      span{
        margin-left: 10px;
        font-weight: 200;
      }
    }
    .section-btn{
      margin-top: 50px;
      background: transparent;
      border: 1px solid white;
      color: white !important;
      float: right;
      padding: 5px 10px 5px 20px;
    }
    .section-btn::after {
      margin-left: 20px;
      margin-right: 10px;
      content: "\2192";
    }
    .section-text{
      margin-top: 4px;
      margin-left: 10px;
      p{
        font-size: 80px !important;
      }
    }
  }
  .featured-text-section{
    height: 580px;
  }
  @media (max-width: 991px) {
    .featured-text-section .section-content {
      .section-title {
        font-size: 30px !important;
      }
    }
    .action-options-wrapper {
      background: white !important;
    }
    .action-option:first-child {
      margin-right: 2px;
    }
    .action-option {
      background: color('primary');
      border: 1px solid color('primary');

      label {
        opacity: 1 !important;
      }
    }
  }
  .col-county{
    display: none;
  }
  .col-type{
    width: 20% !important;
  }
  .col-city{
    width: 19% !important;
  }
  .col-narrower{
    width:  19% !important;
  }
}

body.property {
  .section-realestate-top {
    .property-id-icon {
      font-weight: bold;
      font-size: 1rem;
      background-image: url("../../src/Component/Realestates/Resources/assets/images/icons/property-id.png");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 19px;
    }
  }
  @media (max-width: 690px) {
    .section-realestate-top {
      padding-bottom: unset !important;
    }
  }
}